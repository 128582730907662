<template>
  <PageListLayout
    icon="mdi-cog"
    title="Cài đặt chung"
    subTitle="Tùy chỉnh các cài đặt"
    noFilter
  >
    <v-container>
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <template>
              <div>
                <v-icon>
                  <template>mdi-calendar-clock</template>
                </v-icon>
                Thời gian sắp hết hạn của hợp đồng
              </div>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-col cols="12">
                <v-col
                  cols="12"
                  class="d-flex justify-space-between align-start"
                >
                </v-col>

                <v-form ref="form">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        class="input-form"
                        v-model="form.type"
                        label=" Kiểu thời gian"
                        outlined
                        dense
                        :rules="typeItemRules"
                        hide-details
                        disabled
                        readonly
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        class="input-form"
                        v-model="form.value"
                        label="Nhập thời gian sắp hết hạn hợp đồng"
                        outlined
                        :loading="textFieldLoading"
                        :rules="valueItemRules"
                        type="number"
                        :disabled="textFieldLoading"
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="button-form">
                      <v-btn
                        :loading="btnLoading"
                        @click="submit"
                        depressed
                        color="success"
                      >
                        <v-icon left>mdi-calendar-clock</v-icon>
                        Cập nhật
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
  </PageListLayout>
</template>

<script>
import { TYPE_DATE } from "@/constants/hopdong";
import {
  getAllExpiredDate,
  addExpiredDate,
  updateExpiredDate,
  getCurrentExpiredDate,
} from "@/api/expireddate";
import { type } from "os";
export default {
  data() {
    return {
      form: {
        type: "Ngày",
        value: null,
      },
      typeItemRules: [(v) => !!v || "Kiểu thời gian  không thể bỏ trống"],
      valueItemRules: [(v) => !!v || "Giá trị thời gian không thể bỏ trống"],
      itemExpiredDate: [],
      showInput: false,
      textFieldLoading: false,
      btnLoading: false,
    };
  },

  methods: {
    async getCurrentDate() {
      this.textFieldLoading = true;
      let res = await getCurrentExpiredDate();
      this.form.value = res.value;
      this.textFieldLoading = false;
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        let data = { ...this.form };
        try {
          const res = await addExpiredDate(data);
          this.btnLoading = false;
          this.alert(true);
        } catch (error) {
          this.errorNotify(
            "Lỗi giá trị thới gian không được trùng và không quá 1000 ngày "
          );
          this.btnLoading = false;
        }
      }
    },
    errorNotify(message) {
      this.$toast.error(message, {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    alert(isCreate) {
      this.$emit("on-done");
      this.$toast.info("Cập nhật thành công", {
        position: "top-center",
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
  },
  mounted() {
    this.getCurrentDate();
  },
};
</script>
<style scoped>
.input-form {
  width: 300px;
  margin: 0 auto;
}
.button-form {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
